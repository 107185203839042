import Icon from 'components/Icon';
import RichText from 'components/RichText';

const GalleryTop = ({
    count,
    items,
    prevSlide,
    nextSlide,
    reduceCount,
    showTitle,
    expand,
}) => {
    // For Sliders that show two images at a time need to reduce item length
    const length = reduceCount ? items.length - 1 : items.length;
    const { title } = items[count - 1];
    if (items.length === 1) {
        return null;
    }
    return (
        <div className="gallery__top">
            {showTitle && !expand && (
                <RichText className="gallery__top-title" data={title} />
            )}
            <div className="gallery__count">
                {count} / {items && length}
            </div>
            <div className="gallery__controls">
                <button className="gallery__control" onClick={prevSlide}>
                    <Icon icon="fa-solid fa-arrow-left" />
                </button>
                <button className="gallery__control" onClick={nextSlide}>
                    <Icon icon="fa-solid fa-arrow-right" />
                </button>
            </div>
        </div>
    );
};
export default GalleryTop;
