import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useState, useRef } from 'react';
import {
    Pagination,
    EffectFade,
    Navigation,
    Controller,
    Thumbs,
} from 'swiper/modules';
import classNames from 'classnames';
import Media from 'components/Media';
import RichText from 'components/RichText';
import GalleryTop from '../elements/GalleryTop';
import GalleryThumbs from '../elements/GalleryThumbs';

import parse from 'react-html-parser';
import useExpand from 'lib/hooks/useExpand';

const Gallery1b = ({ settings, introduction, items, footnotes, meta }) => {
    const [controlledSwiper, setControlledSwiper] = useState(null);
    const [swiperOne, setSwiperOne] = useState(null);
    const [swiperThumbs, setSwiperThumbs] = useState(null);
    const [count, setCount] = useState(1);
    const imageRef = useRef();
    const nextSlide = () => {
        if (count >= items.length) {
            swiperOne.slideTo(0);
            swiperThumbs.slideTo(0);
        }
        if (count < items.length) {
            swiperOne.slideNext();
            swiperThumbs.slideNext();
        }
    };
    const prevSlide = () => {
        swiperOne.slidePrev();
        swiperThumbs.slidePrev();
    };
    const { expand } = useExpand();

    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...meta}
        >
            <div className="container">
                <Introduction {...introduction} />

                <div className="gallery gallery--1b">
                    <GalleryTop
                        count={count}
                        items={items}
                        expand={expand}
                        prevSlide={prevSlide}
                        nextSlide={nextSlide}
                        showTitle
                    />
                    <Swiper
                        className={classNames('gallery__main', {
                            'gallery__main--expand': expand,
                        })}
                        modules={[
                            Pagination,
                            EffectFade,
                            Navigation,
                            Controller,
                            Thumbs,
                        ]}
                        pagination={{
                            type: 'progressbar',
                        }}
                        thumbs={{
                            swiper:
                                swiperThumbs && !swiperThumbs.destroyed
                                    ? swiperThumbs
                                    : null,
                        }}
                        controller={{ control: controlledSwiper }}
                        onSwiper={setSwiperOne}
                        onSlideChange={(slide) => {
                            setCount(slide.realIndex + 1);
                        }}
                        touchStartPreventDefault={false}
                        effect="fade"
                    >
                        {items.map((item, index) => {
                            const { media, title, enable_modal } = item;
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="gallery__slide"
                                >
                                    {expand && <>{parse(`${title.value}`)}</>}
                                    <div className="gallery__media-container">
                                        <div ref={imageRef}>
                                            <Media
                                                wrapperClassName={
                                                    'gallery__media'
                                                }
                                                layout="fill"
                                                objectFit={'contain'}
                                                objectPosition={'center'}
                                                media={media}
                                                defaultBackground={'white'}
                                                disableModal={!enable_modal}
                                                disableCaption
                                                disableElevation
                                                slideChange={count}
                                            />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <GalleryThumbs
                        setSwiperThumbs={setSwiperThumbs}
                        settings={settings}
                        items={items}
                    />
                    <Swiper
                        className="gallery__mobile-description"
                        onSwiper={setControlledSwiper}
                        slidesPerView={1}
                        modules={[EffectFade]}
                        effect="fade"
                        autoHeight={true}
                    >
                        {items.map((item, key) => {
                            const { title, theme } = item;
                            return (
                                <SwiperSlide key={key}>
                                    <div
                                        className={classNames({
                                            [`bg-${theme}`]: theme,
                                        })}
                                    >
                                        {title.value && (
                                            <RichText
                                                className="gallery__title"
                                                data={title}
                                            />
                                        )}
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};

export default Gallery1b;
