import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/modules';
import Icon from 'components/Icon';
import Image from 'components/Media/elements/Image';
import classNames from 'classnames';
import Caption from 'components/Caption';

const GalleryThumbs = ({ setSwiperThumbs, items }) => {
    if (items.length === 1) {
        return null;
    }
    return (
        <Swiper
            className="gallery__thumbs"
            modules={[Thumbs]}
            onSwiper={setSwiperThumbs}
            slidesPerView={5.2}
            spaceBetween={4}
            onClick={(swiper) => swiper.slideTo(swiper.clickedIndex)}
        >
            {items.map((item, key) => {
                const { title, media } = item;
                return (
                    <SwiperSlide key={key}>
                        {media.type === 'image' && (
                            <>
                                <Image
                                    wrapperClassName={'gallery__thumb-wrapper'}
                                    className="gallery__thumb"
                                    objectFit={'cover'}
                                    objectPosition={'center'}
                                    layout="fill"
                                    image={media}
                                    alt={`thumbnail of ${title}`}
                                    disableCaption
                                    defaultBackground={'white'}
                                    disableElevation
                                />
                                {media?.caption && (
                                    <Caption
                                        className={classNames(
                                            'gallery__caption',
                                            {
                                                'gallery__caption--center':
                                                    media.caption_alignment ===
                                                    'center',
                                            }
                                        )}
                                        caption={media.caption}
                                    />
                                )}
                            </>
                        )}
                        {media.type === 'video' && (
                            <>
                                <div className="gallery__thumb-wrapper--video">
                                    <Icon
                                        icon={'fa-duotone fa-circle-play'}
                                        className="gallery__thumb-icon"
                                    />
                                    <Image
                                        wrapperClassName={
                                            'gallery__thumb-wrapper'
                                        }
                                        objectFit={'cover'}
                                        objectPosition={'center'}
                                        layout="fill"
                                        className="gallery__thumb"
                                        image={media.thumbnail}
                                        alt={`thumbnail of ${title}`}
                                        defaultBackground={'white'}
                                        disableCaption
                                        disableElevation
                                    />
                                </div>

                                {media?.caption && (
                                    <Caption
                                        alignment={media.caption_alignment}
                                        caption={media?.caption}
                                    />
                                )}
                            </>
                        )}
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};
export default GalleryThumbs;
