/**
 * Gallery Block
 * A Gallery block is used to display a collection of images and optionally descriptive text.
 */

import Gallery1a from './variants/Gallery1a';
import Gallery1b from './variants/Gallery1b';
import Gallery1c from './variants/Gallery1c';
import Gallery1d from './variants/Gallery1d';

import Gallery2a from './variants/Gallery2a';

import Gallery3a from './variants/Gallery3a';

const prefix = 'gallery';

const Gallery = ({ ...block }) => {
    switch (block.variant) {
        case `${prefix}_1a`:
            return <Gallery1a {...block} />;
        case `${prefix}_1b`:
            return <Gallery1b {...block} />;
        case `${prefix}_1c`:
            return <Gallery1c {...block} />;
        case `${prefix}_1d`:
            return <Gallery1d {...block} />;
        case `${prefix}_2a`:
            return <Gallery2a {...block} />;
        case `${prefix}_3a`:
            return <Gallery3a {...block} />;
        default:
            return null;
    }
};

export default Gallery;
