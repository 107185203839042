import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useState } from 'react';
import {
    Pagination,
    Controller,
    EffectFade,
    Navigation,
    Zoom,
} from 'swiper/modules';
import GalleryTop from '../elements/GalleryTop';
import classNames from 'classnames';
import Media from 'components/Media';
import RichText from 'components/RichText';

import useExpand from 'lib/hooks/useExpand';

const Gallery1c = ({ settings, introduction, items, footnotes, meta }) => {
    const [controlledSwiper, setControlledSwiper] = useState(null);
    const [swiperOne, setSwiperOne] = useState(null);
    const [count, setCount] = useState(1);
    const nextSlide = () => {
        swiperOne.slideNext();
    };
    const prevSlide = () => {
        swiperOne.slidePrev();
    };
    const { expand } = useExpand();
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...meta}
        >
            <div className="container">
                <Introduction {...introduction} />
                <div className="gallery gallery--1c">
                    <GalleryTop
                        count={count}
                        items={items}
                        prevSlide={prevSlide}
                        nextSlide={nextSlide}
                    />
                    <Swiper
                        className={classNames('gallery__main', {
                            'gallery__main--expand': expand,
                        })}
                        modules={[
                            Pagination,
                            EffectFade,
                            Navigation,
                            Zoom,
                            Controller,
                        ]}
                        pagination={{
                            type: 'progressbar',
                        }}
                        zoom={true}
                        loop={expand ? false : true}
                        controller={{ control: controlledSwiper }}
                        onSwiper={setSwiperOne}
                        touchStartPreventDefault={false}
                        onSlideChange={(slide) => {
                            setCount(slide.realIndex + 1);
                        }}
                        effect="fade"
                    >
                        {items.map((item, index) => {
                            const { media, title, description } = item;
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="gallery__slide"
                                >
                                    <Media
                                        wrapperClassName={'gallery__media'}
                                        layout="fill"
                                        objectFit={'contain'}
                                        objectPosition={'center'}
                                        media={media}
                                        defaultBackground={'white'}
                                        disableModal
                                        disableCaption
                                        disableElevation
                                        slideChange={count}
                                    />
                                    {expand && (
                                        <div>
                                            <RichText
                                                className="mt-4 gallery__title"
                                                data={title}
                                            />
                                            <RichText
                                                className="mb-4"
                                                data={description}
                                            />
                                        </div>
                                    )}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>

                    <Swiper
                        onSwiper={setControlledSwiper}
                        slidesPerView={1}
                        modules={[EffectFade]}
                        effect="fade"
                        autoHeight={true}
                        loop={true}
                    >
                        {!expand &&
                            items.map((item, index) => {
                                const { title, description, theme } = item;
                                return (
                                    <SwiperSlide key={index}>
                                        <div
                                            className={classNames('mt-6', {
                                                [`bg-${theme}`]: theme,
                                            })}
                                        >
                                            {title?.value && (
                                                <RichText
                                                    className="gallery__title"
                                                    data={title}
                                                />
                                            )}
                                            {description?.value && (
                                                <RichText data={description} />
                                            )}
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                </div>
                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};

export default Gallery1c;
